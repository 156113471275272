#ag-canvas {
  height: 100%;
}

.ag-item :first-child {
  border-radius: 6px;
}
/* @media only screen and (max-width: 700px) {
  .ag-btn-group{
    bottom: -63px;
    left: 27%;
    width: 100%;
  }
} */

.ag-item {
  border-radius: 6px;
  border: 2px #f7e7d0 solid;
  /* background: url('../../assets/images/avatar.png') center no-repeat; */
  height: 100%;
  width: 100%;
}

#ag-resize-container {
  background-image: none !important;
  background-color: #f7e7d0;
  display: flex;
  justify-content: center;
  height: center;
}

#ag-resize-container .ag-item {
  border: none !important;
}

/* button group */

.ag-btn-group {
  height: 70px;
  width: 50%;
  text-align: center;
  margin: auto;
  position: absolute;
  bottom: -69px;
  background: transparent;
  z-index: 11;
  display: flex;
  justify-content: space-around;
  align-items: center;
  left: 27%;
}

.ag-btn-group:hover,
.ag-btn-group.active {
  background: #f7e7d0;
}
.big-video video {
  object-fit: fill !important;
}
.ag-btn {
  z-index: 12;
  opacity: 0;
  color: white;
  height: 50px;
  width: 50px;
  background: #000;
  border-radius: 50%;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  /* width: 35px;
    height: 35px; */
  cursor: pointer;
  font-size: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ag-btn:hover {
  color: #f7e7d0;
}

.ag-btn.disabled,
.ag-btn.disabled:hover {
  color: #f7e7d0;
  cursor: not-allowed;
}

.ag-btn-group:hover .ag-btn,
.ag-btn-group.active .ag-btn {
  opacity: 1;
}

.ag-btn.off.videoControlBtn .ag-icon-camera {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera-off {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera {
  display: inline-block;
}

.ag-btn.off.videoControlBtn .ag-icon-camera-off {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic-off {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic-off {
  display: inline-block;
}
.btn_sec {
  display: flex;
  flex-direction: column;
  background-color: #fff;

}
.textarea_sec {
  bottom: 3px;
  height: 100px;
  position: relative;
}
.textarea_sec::before {
  background: #215352;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  top: -3px;
  width: 100%;
  z-index: 1;
}
.btn_sec textarea {
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;
  border: none;
  border-bottom: 40px solid #fff;
  font-size: 16px;
  height: 100%;
  outline: none;
  overflow: auto;
  padding: 14px 20px;
  display: flex;
  resize: none;
  max-width: 82%;
}
.btn_sec .send-btn {
  padding: 8px 14px;
  background-color: #215352;
  border-radius: 3px;
  color: #fff;
  margin-left: 2px;
  max-height: 45px;
  font-weight: bold;
}
.ReceiveMsg,
.sendsms {
  width: auto;
  /* border-radius: 15px;
  border-bottom-right-radius: 0px;
  margin-bottom: 15px;*/

  line-height: 25px;
  display: inline;
}
.chattext.getsms {
  background: #e4e9ef;
}
.chattext {
  width: auto;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  margin-bottom: 15px;
  background: #ffefe6;

  padding: 0.5rem 1rem;
}
/* *{
  font-family: sans-serif;
}
h1,h4{
  text-align: center;
}
#local{
  position: absolute;
  width: 480px;
  height: 360px;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  border: 3px solid white;
  border-radius: 3px;
}
#local video{
  position: relative !important;
}
#remote{
  position: absolute;
  width: 480px;
  height: 360px;
  top: 40px;
  right: 5px;
}
#remote video{
  height: auto;
  position: relative !important;
} */

* {
  font-family: sans-serif;
}
h1,
h4 {
  text-align: center;
}
#me {
  position: relative;
  width: 50%;
  margin: 0 auto;
  display: block;
}
#me video {
  position: relative !important;
}
#remote-container {
  display: flex;
}
#remote-container video {
  height: auto;
  position: relative !important;
}
.chat-body {
  position: relative;
}
.customload {
  height: 100%;
  width: 250px;
  right: 0;
  background: #fff;
  z-index: 100;
  margin-left: auto;
  left: 0;
  display: block;
  position: fixed;
  opacity: 0.9;
  top: 70px;
}
.customload .spinner-border {
  position: absolute;
  top: 40%;
  border: 0.4em solid #ff6f00;
  border-right-color: transparent;
  left: 45%;
}

@media only screen and (max-width: 600px) {
  .customload {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .msg-in-section{
    width: 90% !important;
  }
}

.session-screen-wrapper .btn-primary {
  background: #000000;
  font-size: 18px;
  padding: 5px 20px;
  border: none;
  z-index: 222222222;
}
.session-screen-wrapper .btn-primary:active,
.session-screen-wrapper .btn-primary:hover {
  /* border:1px solid #000; */
  background: #ff6f00;
  color: #fff;
}
.btn-primary.disabled,
.btn-primary:disabled:hover {
  cursor: not-allowed;
}
.Chat_body {
  /* width: 100%; */
  /* overflow-x: hidden; */
  min-height: 72vh;
  max-height: 72vh;
  /* bottom: 3px; */
  /* flex: 0 0 100px; */
  /* height: auto; */
  /* position: relative; */
  padding-left: 10px;
  padding-right: 10px;
  flex: 1 auto;
  margin-top: auto;
  overflow: auto;
  padding-bottom: 20px;
}
.message-send {
  margin: 0 5px;
  display: flex;
}
.message_r_p {
  color: #080f1a;
  font-family: sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  height: auto;
  max-width: 550px;
  border-radius: 10px 10px 10px 0;
  padding: 4px 10px 7px !important;
  font-size: 14px;
  text-shadow: 0 0px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  word-wrap: break-word;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.message_s_p {
  color: #080f1a;
  font-family: sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  /* background: #e2e2e2;
  padding: 8px;
  border-radius: 8px; */
  height: auto;
  max-width: 550px;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  font-size: 14px;
  text-shadow: 0 0px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  display: inline-block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.message-show {
  display: flex;
}
.action-buttons {
  bottom: 2px;
  display: flex;
  height: 40px;
  padding: 5px;
  position: absolute;
  width: 98%;
  align-items: flex-end;
}
.date-hr {
  display: flex;
  flex-direction: row;
  padding: 0 20px;
}
.date-hr:before,
.date-hr:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #bbb8b8;
  margin: auto;
}
.date-hr span {
  margin: 0 10px;
  background-color: #bbccf3;
  padding: 3px 5px;
  border-radius: 3px;
  color: #000;
}
.send-section {
  display: flex;
  position: absolute;
  right: 12px;
  overflow: hidden;
}

button.save-res{
  margin-top: 10px;
  float: right;
  background: #2d98f3;
  border:0;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    font-size:17px;
    cursor:pointer;
}