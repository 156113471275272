@import url(https://fonts.googleapis.com/css2?family=Hanken+Grotesk&family=Source+Serif+Pro:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hanken+Grotesk&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 0;
  font-size: 14px;
  color: #777;
  background: #f9f9f9;
  font-family: "Open Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#ag-canvas {
  height: 100%;
}

.ag-item :first-child {
  border-radius: 6px;
}
/* @media only screen and (max-width: 700px) {
  .ag-btn-group{
    bottom: -63px;
    left: 27%;
    width: 100%;
  }
} */

.ag-item {
  border-radius: 6px;
  border: 2px #f7e7d0 solid;
  /* background: url('../../assets/images/avatar.png') center no-repeat; */
  height: 100%;
  width: 100%;
}

#ag-resize-container {
  background-image: none !important;
  background-color: #f7e7d0;
  display: flex;
  justify-content: center;
  height: center;
}

#ag-resize-container .ag-item {
  border: none !important;
}

/* button group */

.ag-btn-group {
  height: 70px;
  width: 50%;
  text-align: center;
  margin: auto;
  position: absolute;
  bottom: -69px;
  background: transparent;
  z-index: 11;
  display: flex;
  justify-content: space-around;
  align-items: center;
  left: 27%;
}

.ag-btn-group:hover,
.ag-btn-group.active {
  background: #f7e7d0;
}
.big-video video {
  object-fit: fill !important;
}
.ag-btn {
  z-index: 12;
  opacity: 0;
  color: white;
  height: 50px;
  width: 50px;
  background: #000;
  border-radius: 50%;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  /* width: 35px;
    height: 35px; */
  cursor: pointer;
  font-size: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ag-btn:hover {
  color: #f7e7d0;
}

.ag-btn.disabled,
.ag-btn.disabled:hover {
  color: #f7e7d0;
  cursor: not-allowed;
}

.ag-btn-group:hover .ag-btn,
.ag-btn-group.active .ag-btn {
  opacity: 1;
}

.ag-btn.off.videoControlBtn .ag-icon-camera {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera-off {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera {
  display: inline-block;
}

.ag-btn.off.videoControlBtn .ag-icon-camera-off {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic-off {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic-off {
  display: inline-block;
}
.btn_sec {
  display: flex;
  flex-direction: column;
  background-color: #fff;

}
.textarea_sec {
  bottom: 3px;
  height: 100px;
  position: relative;
}
.textarea_sec::before {
  background: #215352;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  top: -3px;
  width: 100%;
  z-index: 1;
}
.btn_sec textarea {
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;
  border: none;
  border-bottom: 40px solid #fff;
  font-size: 16px;
  height: 100%;
  outline: none;
  overflow: auto;
  padding: 14px 20px;
  display: flex;
  resize: none;
  max-width: 82%;
}
.btn_sec .send-btn {
  padding: 8px 14px;
  background-color: #215352;
  border-radius: 3px;
  color: #fff;
  margin-left: 2px;
  max-height: 45px;
  font-weight: bold;
}
.ReceiveMsg,
.sendsms {
  width: auto;
  /* border-radius: 15px;
  border-bottom-right-radius: 0px;
  margin-bottom: 15px;*/

  line-height: 25px;
  display: inline;
}
.chattext.getsms {
  background: #e4e9ef;
}
.chattext {
  width: auto;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  margin-bottom: 15px;
  background: #ffefe6;

  padding: 0.5rem 1rem;
}
/* *{
  font-family: sans-serif;
}
h1,h4{
  text-align: center;
}
#local{
  position: absolute;
  width: 480px;
  height: 360px;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  border: 3px solid white;
  border-radius: 3px;
}
#local video{
  position: relative !important;
}
#remote{
  position: absolute;
  width: 480px;
  height: 360px;
  top: 40px;
  right: 5px;
}
#remote video{
  height: auto;
  position: relative !important;
} */

* {
  font-family: sans-serif;
}
h1,
h4 {
  text-align: center;
}
#me {
  position: relative;
  width: 50%;
  margin: 0 auto;
  display: block;
}
#me video {
  position: relative !important;
}
#remote-container {
  display: flex;
}
#remote-container video {
  height: auto;
  position: relative !important;
}
.chat-body {
  position: relative;
}
.customload {
  height: 100%;
  width: 250px;
  right: 0;
  background: #fff;
  z-index: 100;
  margin-left: auto;
  left: 0;
  display: block;
  position: fixed;
  opacity: 0.9;
  top: 70px;
}
.customload .spinner-border {
  position: absolute;
  top: 40%;
  border: 0.4em solid #ff6f00;
  border-right-color: transparent;
  left: 45%;
}

@media only screen and (max-width: 600px) {
  .customload {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .msg-in-section{
    width: 90% !important;
  }
}

.session-screen-wrapper .btn-primary {
  background: #000000;
  font-size: 18px;
  padding: 5px 20px;
  border: none;
  z-index: 222222222;
}
.session-screen-wrapper .btn-primary:active,
.session-screen-wrapper .btn-primary:hover {
  /* border:1px solid #000; */
  background: #ff6f00;
  color: #fff;
}
.btn-primary.disabled,
.btn-primary:disabled:hover {
  cursor: not-allowed;
}
.Chat_body {
  /* width: 100%; */
  /* overflow-x: hidden; */
  min-height: 72vh;
  max-height: 72vh;
  /* bottom: 3px; */
  /* flex: 0 0 100px; */
  /* height: auto; */
  /* position: relative; */
  padding-left: 10px;
  padding-right: 10px;
  flex: 1 1 auto;
  margin-top: auto;
  overflow: auto;
  padding-bottom: 20px;
}
.message-send {
  margin: 0 5px;
  display: flex;
}
.message_r_p {
  color: #080f1a;
  font-family: sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  height: auto;
  max-width: 550px;
  border-radius: 10px 10px 10px 0;
  padding: 4px 10px 7px !important;
  font-size: 14px;
  text-shadow: 0 0px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  word-wrap: break-word;
  -webkit-margin-before: 0.5em;
          margin-block-start: 0.5em;
  -webkit-margin-after: 0.5em;
          margin-block-end: 0.5em;
}
.message_s_p {
  color: #080f1a;
  font-family: sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  /* background: #e2e2e2;
  padding: 8px;
  border-radius: 8px; */
  height: auto;
  max-width: 550px;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  font-size: 14px;
  text-shadow: 0 0px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  display: inline-block;
  -webkit-margin-before: 0.5em;
          margin-block-start: 0.5em;
  -webkit-margin-after: 0.5em;
          margin-block-end: 0.5em;
}
.message-show {
  display: flex;
}
.action-buttons {
  bottom: 2px;
  display: flex;
  height: 40px;
  padding: 5px;
  position: absolute;
  width: 98%;
  align-items: flex-end;
}
.date-hr {
  display: flex;
  flex-direction: row;
  padding: 0 20px;
}
.date-hr:before,
.date-hr:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #bbb8b8;
  margin: auto;
}
.date-hr span {
  margin: 0 10px;
  background-color: #bbccf3;
  padding: 3px 5px;
  border-radius: 3px;
  color: #000;
}
.send-section {
  display: flex;
  position: absolute;
  right: 12px;
  overflow: hidden;
}

button.save-res{
  margin-top: 10px;
  float: right;
  background: #2d98f3;
  border:0;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    font-size:17px;
    cursor:pointer;
}

.Chat-container {
  align-items: stretch;
  border-top: 4px solid #0566ff;
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  justify-content: stretch;
  min-width: 1024px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
}

.visitor-conversations {
  flex: 3 1;
  background: #21324e;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #858484;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background-color: #fcfcfd;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(158, 157, 157);
}

.chat-on {
  width: 60%;
  height: 100%;
}
.profile {
  width: 20%;
  height: 100%;
}
.show_fletter {
  font-size: 2em;
  text-align: center;
  border-radius: 5px;
  background: #ca403c;
  vertical-align: middle;
  color: #f8f5f5;
}
.visitor-list {
  list-style: none;
  margin-left: -40px;
}
.visitor-list li a {
  /* border-bottom: 1px solid #eee; */
  display: flex;
  align-items: center;
  justify-self: center;
}
.top-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
#search-wrapper {
  padding: 14px 10px;
  margin-bottom: 20px;
}
#search-wrapper input:focus,
#search-wrapper input:hover {
  /* background-color: #1c2b45; */
  cursor: pointer;
  color: #000;
}

#search-wrapper span {
  /* color: #647491; */
  cursor: pointer;
  font-size: 19px;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 11px;
  border-radius: 5px;
}
.ion-android-search:before {
  content: "\f2f5";
}
ul.visitor-list li a {
  position: relative;
  /* display: block; */
  padding: 15px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
ul.visitor-list li:active a {
  background-color: #f1f5fc10;
}
ul.visitor-list a h4 .name {
  flex: 1 1;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
  cursor: pointer;
}
ul.visitor-list .name {
  display: flex;
  justify-content: space-between;
}
.name .date {
  position: absolute;
  right: 12px;
  color: #777;
}
#visitor-conversations .visitor-list a:not(.link) .avatar {
  cursor: pointer;
  outline: none;
  position: relative;
}
ul.visitor-list a .avatar {
  float: left;
  width: 38px;
  height: 38px;
  margin-right: 10px;
  margin-top: 0px;
  padding: 2px 10px;
  font-weight: bold;
}
.name-section {
  display: flex;
  flex:1 1;
  flex-direction: column;
}
.visitor-list li a .name,
.visitor-list li a .name:hover {
  color: #fff;
}
.last-text {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  color: #777;
  padding-top: 5px;
}
.message-wrapper .avatar {
  float: left;
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.avatar {
  border-radius: 50%;
  color: #fff;
  cursor: default;
  font-size: 18px;
  line-height: 2em;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
}
#search-wrapper input {
  background: 0 0;
  border: none;
  border-radius: 16px;
  color: #fff;
  font-size: 14.1px;
  height: 32px;
  line-height: 32px;
  outline: none;
  padding-left: 40px;
  padding-right: 10px;
  width: 100%;
}
ul.visitor-list a .avatar.offline:before {
  background: #21324e;
  box-shadow: inset 0 0 0 2px #e81332;
}
ul.visitor-list a h4 .label:not(.label-primary) {
  color: #8796af;
  font-size: 12.1px;
}
ul.visitor-list li.active{
  background-color: #c1d9cc;
}
ul.visitor-list a:hover {
  background-color: #c1d9cc;
}
ul.visitor-list a h4 .label {
  border-radius: 2px;
  font-size: 10px;
  font-weight: 400;
  max-width: 65px;
  opacity: 1;
  overflow: hidden;
  padding: 2px 3px;
  text-overflow: ellipsis;
  transition: opacity 0.2s;
  white-space: nowrap;
}
.label {
  border-radius: 0.25em;
  color: #fff;
  display: inline;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  padding: 0.2em 0.6em 0.3em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.css-ucb1au {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.css-mfd53b {
  fill: rgb(100, 116, 145);
  display: flex;
  align-items: center;
  margin-left: auto;
  order: 2;
  height: 20px;
}
.Profile {
  flex: 3.5 1;
  background-color: #eff2f6;
}
.chat-initial {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.chat-i-image {
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #215352;
  padding: 2px;
}
.chat-i-image span {
  font-weight: 700;
  color: #fff;
  font-size: 26px;
}
.chat-user-name {
  display: flex;
  flex-direction: column;
  font: 1em sans-serif;
  padding-left: 10px;
  font-weight: 600;
  text-transform: capitalize;
}
.name-rightside {
  color: #215352;
  margin: 0px;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out 0s;
  font-size: 20px;
}
.country-rightside {
  color: rgb(100, 116, 145);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  line-height: 16.8px;
  letter-spacing: 0px;
}
.chat-talk {
  flex: 6 1;
  float: left;
  height: 100%;
  outline: none;

  /* width: calc(100% - 380px); */
}
.chat-talk .conversation {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}
.chat-talk .log {
  flex: 0 1 auto;
  margin-top: auto;
  overflow: auto;
  padding-bottom: 20px;
}
.css-12rpjbd {
  text-align: center;
  padding: 10px 20px;
}
.chat-talk .message-wrapper.with-avatar {
  margin-top: 10px;
}
.chat-talk .message-wrapper:after,
.chat-talk .message-wrapper:before {
  clear: both;
  content: "";
  display: table;
}
.chat-talk .new-message-wrapper {
  bottom: 3px;
  flex: 0 0 100px;
  height: 100px;
  position: relative;
  top: 360px;
}
.chat-talk .new-message-wrapper:before {
  background: #0566ff;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  top: -3px;
  width: 98%;
  z-index: 2;
}
.chat-talk .new-message-wrapper textarea.cursor-pointer {
  cursor: pointer;
}
.chat-talk .new-message-wrapper textarea {
  border: none;
  border-bottom: 40px solid #fff;
  font-size: 16.1px;
  height: 100%;
  outline: none;
  overflow: auto;
  padding: 14px 20px;
  resize: none;
  width: 93%;
}
.css-2h2gzl {
  visibility: visible;
  display: flex;
  position: absolute;
  right: 12px;
  overflow: hidden;
}
.css-twwo7u {
  margin-right: 10px;
}
.css-1hskc2t {
  background: transparent;
  border: 0px;
  display: inline-flex;
  box-shadow: none;
  outline: none;
  margin-right: auto;
  padding: 0px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgb(135, 150, 175);
}
.Profile-avatar {
  display: flex;
}
.Profile-details {
  background-color: #fffefec2;
  margin: 10px;
  padding: 10px 18px;
  /* min-height: 30vh; */
  color: #7e7d7d;
  font-size: 1em;
  box-shadow: rgb(0 27 71 / 8%) 0px 3px 8px;
  border-radius: 5px;
}
.profile-contact-heading {
  text-transform: uppercase;
  color: #30563f;
  /* letter-spacing: 1.9px; */
  font-weight: bold;
}
.profile-contact-info {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
.profile-contact-info span {
  padding-left: 10px;
}
.conversation-header {
  background-color: #fff;
  border-bottom: 1px solid transparent;
  box-shadow: 0 2px 2px rgb(0 0 60 / 8%);
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  z-index: 1;
}

.msg-in-section {
  display: flex;
  flex-flow: row;
  margin-top: 10px;
  width: 60%;
}
.in-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  /* background-color: #d3e; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  color: #fff;
  margin: 5px;
}
.msg-in-nt {
  display: flex;
  flex-flow: column;
  background-color: #eceaea;
}
.in-name {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  margin-top: 5px;
}
.in-name p {
  margin: 0;
  display: inline-block !important;
  font-weight: bolder;
  color: #080f1a;
  font-family: sans-serif;
  font-size: 0.9em;
  line-height: 1.42857143;
}
.in-name span {
  margin-left: 10px;
  color: #647491;
  font-size: 12px;
}
/* .msg-in-section:hover {
  background-color: #efefef;
} */
.dashboard-link {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 7px 0px;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  box-shadow: none;
}
.dashboard-link button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  background: transparent;
  color: #0566ff;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  padding: 0px;
  transition: all 0.2s ease-in-out 0s;
}
.ds-al {
  display: flex;
  align-items: center;
}
.ds-al span {
  padding-left: 10px;
}
.chat-at-b {
  position: absolute;
  bottom: 0;
  width: 100%;
}

section.page_404 {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 31%;
}
.chat-back-button {
  padding: 8px 15px !important;
  color: #215352;
  background-color: #a1dcbc;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
  font-family: inherit;
  font-size: 1rem;
  text-shadow: none;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none !important;
  font-weight: 600 !important;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  border-radius: 3px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  outline: none!important;
  border:none!important;
}
.chat-back-button:hover {
  color: #fff;
  background-color: #248c46;
}
.chat-back-button span:nth-child(1){
  padding-right: 10px;
}

.loader-center {
    justify-content: center;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    text-align: center;
    height: 70vh;
    align-items: center;
    color: #fff;
}
.file-msg{
  max-width: 400px;
  height: auto;
  background-size: contain;
}
.file-msg img{
  width:100%
}
a.cloud-dwnl {
  padding: 6px 10px;
  /* position: relative; */
  background-color: #6fc8f3;
  color: #1f1e1e;
  border-radius: 5px;
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
}

.file-details-pop {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* background: #fff; */
  /* width: 400px; */
}
.file-details-w {
  width: 400px;
  background: #fff;
  padding: 25px 30px;
    border-radius: 5px;
}
.btn-file{
  padding: 7px 11px;
    outline: none;
    border-radius: 5px;
    border: 0;
    margin-right: 10px;
    cursor: pointer;
}
.bg-color2d{
    background-color: #2dabdc;
  }

/* file upload */

.file-drop {
  /* relatively position the container bc the contents are absolute */
  /* display: flex;
  align-items: center;
  height: 80%;
  width: 100%; */
  
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 2px;
  left: 0;
  height: 85%;
  width: 99%;
  border-radius: 2px;
  z-index: -100;
  opacity: 0;

  /* horizontally and vertically center all content */
  display: flex;
  display: -ms-flexbox;

  flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: 2px dashed rgb(8, 8, 8);
  background-color: rgb(224, 223, 223);
  box-shadow: none;
  z-index: 1005;
  opacity: 1;
  /* typography */
  color: rgb(36, 36, 36);
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #0f134e;
}

/* Quick response */

#careC-popup-btn{
  padding: 7px 11px;
  cursor: pointer;
}
.careC-popup-flex{
  margin:30px;
}
.careC-popup-container {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(21, 17, 17, 0.61);
    display: flex;
    align-items: center;
    
}
.careC-popup-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}
.careC-popup-content p{
    font-size: 17px;
    padding: 10px;
    line-height: 20px;
}
#close{
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.careC-popup-content span:hover,
.careC-popup-content span:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.popQshow{
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
 
 .input-C{
  min-height: 199px;
    padding: 25px 20px 15px 25px;
    font-size: 15px;
    display: block;
    width: 93%;
    background: transparent;
    color: #333333;
    line-height: 1.2;
}
.response-list{
  display: flex;
  min-height: 40vh;
  align-items: center;
  justify-content: center;
}

.order_container{
  display: flex;
  flex-direction: row;
  /* margin: 0px auto; */
}
.btn-order{
  background-color: #ddefe5;
  color:#215352;
  font-weight:bold;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  border: .2px solid #215352;
  padding: 6px 16px;
  border-radius: 2px;
}
.order-inpt{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.order-label{
  font-family: 'Source Serif Pro';
  font-size: 17px;
  font-weight: bold;
}
.questions_{
  font-size: 14px;
  margin-top: 20px;
}
.questions_ label{
  margin-bottom: 10px;
  font-weight: bold;
}
.questions_button{
  background-color: #699ff0;
  color:#fff;
  font-weight:bold;
  /* box-shadow: 0px 0px 1px 1px rgb(120, 119, 119); */
  margin: 4px;
  padding:5px 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.tex_p{
  font-family: 'Hanken Grotesk';
}
.header_font{
  font-family: 'Source Serif Pro'
}
._text_color{
  color:#215352;
}
.bg_color{
  background-color:#215352;
}
.bg_secondary{
  background-color: #ddefe5;
}
.mr-auto{
  margin-right: auto !important;
}
.ml-auto{
  margin-left: auto !important;
}
.rightdiv{
  display: flex !important;
  justify-content: flex-end !important;
}
.bg_secondary_{
  background-color: #e7f4ea;
}
